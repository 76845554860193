<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Escanea QR </v-card-title>

        <v-card-text>
          <StreamBarcodeReader v-if="dialog"   @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="pararCamara()"> cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
import { StreamBarcodeReader } from 'vue-barcode-reader'
import { ref } from '@vue/composition-api'
export default {
  components: {
    StreamBarcodeReader,
  },
  methods: {
    pararCamara() {
      alert("pararCamara")
     /* this.$refs.scanner.codeReader.stream.getTracks().forEach(function (track) {
        track.stop()
      })*/
      this.dialog = false
    },
    stopBarcodeScanner() {
      // Suponiendo que el componente StreamBarcodeReader tiene un método stop()
      // que detiene el stream del lector de códigos de barras
      this.$refs.scanner.codeReader.stream.getTracks().forEach(function (track) {
        track.stop()
      })
    },
  },
  beforeDestroy() {
    alert("beforeDestroy")
    this.stopBarcodeScanner()
  },
  setup() {
    const dialog = ref(false)
    const onDecode = () => {
      alert('onDecode')
    }
    const onLoaded = () => {
      console.log('onLoaded')
    }

    const abrir = () => {
      dialog.value = true
    }

    return {
      dialog,
      abrir,
      onDecode,
      onLoaded,
    }
  },
}
</script>