<template>
  <div>
    <v-row v-if="!errorVersion && !errorInicializar">
      <v-col lg="8" md="6" class="d-none d-md-block">
        <v-container v-if="mesoneros.length > 0">
          <v-row class="contain-mesonero text-center">
            <v-col class="" v-for="(item, index) in mesoneros" :key="index" lg="3" md="4">
              <a @click="seleccionarMesonero(item)" class="text--center">
                <v-avatar size="150">
                  <v-img
                    :src="`${confix}/empleado/img/${item.img}`"
                    :lazy-src="`${confix}/empleado/img/empleado_default.jpg`"
                    height="150px"
                  >
                  </v-img>
                </v-avatar>
                <div>
                  <h4 class="text-center secondary--text">{{ item.nombre + ' ' + item.apellido }}</h4>
                </div>
              </a>
            </v-col>
          </v-row>
        </v-container>
        <div v-else>
          <v-img :src="require(`@/assets/images/fondo-login.png`)" style="height: calc(100vh - 50px)" contain></v-img>
        </div>
      </v-col>
      <v-col lg="4" md="6" sm="12" class="my-0 py-0">
        <div class="auth-wrapper auth-v1">
          <div class="auth-inner">
            <v-card class="auth-card">
              <!-- logo -->
              <v-card-text class="d-flex align-center justify-center py-2 my-4">
                <router-link to="/" class="d-flex align-center">
                  <v-img
                    :src="require(`@/assets/images/logos/${logoI}`)"
                    width="250px"
                    alt="logo"
                    contain
                    class="my-0"
                  ></v-img>
                </router-link>
              </v-card-text>

              <!-- login form -->
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="email"
                    outlined
                    label="Usuario"
                    placeholder="Usuario"
                    hide-details
                    class="mb-3"
                    v-on:keyup.enter="onEnter"
                  >
                  </v-text-field>

                  <v-text-field
                    ref="passwordref"
                    v-model="password"
                    outlined
                    :type="isPasswordVisible ? 'text' : 'password'"
                    label="Contraseña"
                    placeholder="············"
                    :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                    hide-details
                    @click:append="isPasswordVisible = !isPasswordVisible"
                    v-on:keyup.enter="onEnter"
                  >
                  </v-text-field>

                  <div v-if="false" class="d-flex align-center justify-space-between flex-wrap">
                    <v-checkbox label="Recuerdame" hide-details class="me-3 mt-1"> </v-checkbox>

                    <!-- forgot link -->
                    <a v-if="false" href="javascript:void(0)" class="mt-1"> ¿Olvido su contraseña? </a>
                  </div>

                  <v-btn
                    :loading="loading"
                    :disabled="loading"
                    block
                    color="primary"
                    class="mt-6"
                    v-on:click="IniciarSesion()"
                  >
                    Iniciar Sesión
                  </v-btn>
                </v-form>
              </v-card-text>

              <!-- divider -->
              <v-card-text class="d-flex align-center mt-2">
                <v-divider></v-divider>
                <span class="mx-5">by</span>
                <v-divider></v-divider>
              </v-card-text>

              <!-- social links -->
              <v-card-actions class="d-flex justify-center">
                <v-img
                  :src="require(`@/assets/images/logos/logoarty/ArtyTechnologyGris.png`)"
                  height="50px"
                  alt="logo"
                  contain
                  class="my-0"
                ></v-img>
              </v-card-actions>
              <p class="text-center">
                <small>V.{{ VERSION }}</small>
              </p>
              <div class="text-center">
                <v-avatar size="20" class="mr-2">
                  <v-img
                    :lazy-src="require(`@/assets/images/navegadores/desconocido.png`)"
                    :src="require(`@/assets/images/navegadores/${funciones.getMovil()}.png`)"
                    alt="John"
                  />
                </v-avatar>
                <v-avatar size="20" class="mr-1">
                  <v-img
                    :lazy-src="require(`@/assets/images/navegadores/desconocido.png`)"
                    :src="require(`@/assets/images/navegadores/${funciones.getNavegador()}.png`)"
                    alt="John"
                  />
                </v-avatar>

                <PruebaConexion />

                <a :href="linkAPK" icon color="primary">
                  <v-icon color="primary">{{ icons.mdiDownload }}</v-icon>
                </a>
              </div>
            </v-card>
          </div>

          <!-- background triangle shape  -->

          <v-snackbar v-model="isSnackbarVisible" multi-line :light="$vuetify.theme.dark">
            {{ mensaje }}

            <template #action="{ attrs }">
              <v-btn color="error" text v-bind="attrs" @click="isSnackbarVisible = false"> Close </v-btn>
            </template>
          </v-snackbar>
        </div>
      </v-col>
    </v-row>

    <div class="auth-wrapper auth-v1" v-else-if="errorVersion == true">
      <div class="auth-inner">
        <v-card class="auth-card">
          <!-- logo -->
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link to="/" class="d-flex align-center">
              <v-img
                :src="require(`@/assets/images/logos/${logoI}`)"
                width="250px"
                alt="logo"
                contain
                class="my-0"
              ></v-img>
            </router-link>
          </v-card-title>

          <!-- title -->
          <v-card-text>
            <p class="text-2xl font-weight-semibold text-center text--primary mb-2">Actualizacion del sistema</p>
            <p class="mb-2 text-center">Tenemos una actualizacion del sistema solo recarga la pagina.</p>
          </v-card-text>

          <!-- login form -->

          <!-- divider -->
          <v-card-text class="d-flex align-center mt-2">
            <v-divider></v-divider>
            <span class="mx-5">Version</span>
            <v-divider></v-divider>
          </v-card-text>

          <!-- social link -->
          <v-card-text class="text-center">
            <span>Version Actual</span>
            <h5>{{ versionActual }}</h5>
            <span>Version Sistema</span>
            <h5>{{ VERSION }}</h5>
            <v-btn @click="refreshPage()" class="primary mt-2">Recargar</v-btn> <br />
            <small class="error--text"
              >Si continuas con el problema. En PC preciona CTR + F5 , en movil borra el historial de navegacion</small
            >
          </v-card-text>
        </v-card>
      </div>
    </div>

    <div class="auth-wrapper auth-v1" v-else>
      <div class="auth-inner">
        <v-card class="auth-card">
          <!-- logo -->
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link to="/" class="d-flex align-center">
              <v-img
                :src="require(`@/assets/images/logos/${logoI}`)"
                width="250px"
                alt="logo"
                contain
                class="my-0"
              ></v-img>
            </router-link>
          </v-card-title>

          <!-- title -->
          <v-card-text>
            <p class="text-2xl font-weight-semibold text-center text--primary mb-2">Inicializar la base de datos</p>
          </v-card-text>

          <!-- login form -->

          <!-- divider -->
          <v-card-text class="d-flex align-center mt-2">
            <v-divider></v-divider>
            <span class="mx-5">Bienvenido</span>
            <v-divider></v-divider>
          </v-card-text>

          <!-- social link -->
          <v-card-text class="text-center">
            <v-btn @click="iniciarlizar()" class="primary mt-2">Inicializar</v-btn> <br />
            <small class="error--text"
              >Si continuas accediendo a esta pagina por favor informar al administrador del sistema</small
            >
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline, mdiDownload } from '@mdi/js'
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import axios from '@/api'
import SeguridadService from '@/api/servicios/SeguridadService'
//import   config from '@/api/config'
import MaestroService from '@/api/servicios/MaestroService'
import PruebaConexion from '@/components/PruebaConexion.vue'
import VersionService from '@/api/servicios/VersionService'
import { socket } from '@/plugins/sockets'
import ModalLeerQr from '../../components/ModalLeerQr.vue'
import funciones from '@/funciones/funciones'
export default {
  components: {
    PruebaConexion,
    ModalLeerQr,
  },
  methods: {
    refreshPage() {
      location.reload()
    },
  },

  setup() {
    const confix = config.VUE_APP_URL_IMG
    const VERSION = process.env.VUE_APP_VERSION
    const isSnackbarVisible = ref(false)
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const mensaje = ref('')
    const loading = ref(false)
    const mesoneros = ref([])
    const passwordref = ref(null)
    const logoI = config.VUE_APP_IMG_LOGO_LOGIN
    const linkAPK = config.VUE_APP_LINK_APK
    onBeforeMount(() => {
      cargarVersion()
    })
    const ModalLeerQrRef = ref()
    const seleccionarMesonero = mesonero => {
      email.value = mesonero.usuario
      password.value = ''
      passwordref.value.focus()
    }
    const versionActual = ref('')
    const errorVersion = ref(false)
    const errorInicializar = ref(false)
    const abrirQr = () => {
      ModalLeerQrRef.value.abrir()
    }
    const iniciarlizar = () => {
      VersionService.aplicarVersion()
        .then(response => {
          if (response.data.includes('ok')) {
            this.location.reload()
          } else {
            alert(response.data)
          }
        })
        .catch(error => {
          console.error(error)
          store.commit('setAlert', {
            message: error,
            type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
            funcion: 'iniciarlizar',
          })
        })
    }

    const cargarVersion = async () => {
      await MaestroService.versionSistemaActual({ actual: process.env.VUE_APP_VERSION })
        .then(response => {
          if (response.data.mensaje == 'ERROR') {
            errorVersion.value = true
            versionActual.value = response.data.datos[0].db
          } else if (response.data.mensaje == 'inicializar') {
            errorInicializar.value = true
          }
        })
        .catch(error => {
          console.error(error)
          store.commit('setAlert', {
            message: error,
            type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
            funcion: 'iniciarlizar',
          })
        })

      listarMesoneros()
    }

    const listarMesoneros = () => {
      SeguridadService.listarMesonerosLogin()
        .then(response => {
          if (response.data.datos) {
            mesoneros.value = response.data.datos
          }
        })
        .catch(error => {
          mensaje.value = 'ups hubo un error, ' + error
        })
    }

    const DatosConfiguracion = () => {
      MaestroService.DatosConfiguracion()
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            store.commit('setconfiguracionGeneral', response.data.datos)
          }
        })
        .catch(error => {
          console.error(error)
          store.commit('setAlert', {
            message: error,
            type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
            funcion: 'DatosConfiguracion',
          })
        })
    }

    const IniciarSesion = () => {
      console.log('login')

      //store.commit('setUserData', { id: 1, userName: 'angel labori', autenticate: true })
      //router.push('/')
      loading.value = true
      axios
        .post('/login/iniciarSesion', {
          user: email.value,
          password: password.value,
        })
        .then(function (response) {
          loading.value = false
          if (response.data.mensaje == 'INICIO_SESION_EXITOSA') {
            store.commit('setUserData', { ...response.data.datos, autenticate: true })
            DatosConfiguracion()
            //this.registrarSocket({ usuario: email.value })
            socket.emit('join', { usuario: email.value })
            router.push('/')
          } else {
            isSnackbarVisible.value = true
            if (response.data.mensaje == 'INICIO_SESION_FALLIDA') {
              mensaje.value = 'Usuario o clave invalida'
            } else {
              mensaje.value = response.data.mensaje
            }
          }
          console.log(response)
        })
        .catch(function (error) {
          loading.value = false
          isSnackbarVisible.value = true
          mensaje.value = 'Ups, disculpe hubo un error.'
          console.error(error)
          store.commit('setAlert', {
            message: error,
            type: 'error',
            error: error,
            funcion: 'IniciarSesion',
          })
        })
    }

    const onEnter = () => {
      IniciarSesion()
    }

    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isSnackbarVisible,
      isPasswordVisible,
      email,
      password,
      socialLink,
      IniciarSesion,
      store,
      mensaje,
      loading,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiDownload,
      },
      onEnter,
      mesoneros,
      //config,
      passwordref,
      seleccionarMesonero,
      logoI,
      VERSION,
      linkAPK,
      cargarVersion,
      versionActual,
      errorVersion,
      errorInicializar,
      iniciarlizar,
      confix,
      abrirQr,
      ModalLeerQrRef,
      funciones,
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.contain-mesonero {
  height: 100vh;
  overflow-y: auto;
}
</style>
