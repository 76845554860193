//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class VersionService {


  aplicarVersion(datos) {
    return http.get("versiones/aplicarVersion"   )
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  } 

}





export default new VersionService();